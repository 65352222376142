<template>
  <!-- 充电订单 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="90px">
        <el-form-item label="订单号">
          <el-input style="width: 230px" clearable v-model="searchData.order_no" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input style="width: 230px" clearable v-model="searchData.mobile" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input style="width: 230px" clearable v-model="searchData.uid" placeholder="请输入" size="small"></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select style="width: 230px" v-model="searchData.order_status" placeholder="请选择" size="small" clearable>
            <el-option label="占位中" :value="10"> </el-option>
            <el-option label="充电中" :value="20"> </el-option>
            <el-option label="已结束" :value="30"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="停车场">
          <SelectStation v-model="searchData.station_id" width="230px"></SelectStation>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            style="width: 230px"
            v-model="searchData.timeSlot"
            size="small"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-tabs v-model="searchData.type">
      <el-tab-pane label="全部" name="-1"></el-tab-pane>
      <el-tab-pane label="普通" name="0"></el-tab-pane>
      <el-tab-pane label="预约" name="1"></el-tab-pane>
    </el-tabs> -->
    <!-- <el-divider></el-divider> -->
    <div class="header-btn">
      <!-- <el-button @click="$router.push({ name: 'ChargeGunAdd' })" type="primary" icon="el-icon-plus" size="small">新增</el-button> -->
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { getListAPI } from './api'
import SelectStation from '@/views/components/select-station.vue'
import { dict_order_status } from '@/enum/dict.js'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '订单编号',
    prop: 'order_no',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['order_no']}</p>
    }
  },
  {
    label: '用户信息',
    prop: 'user',
    minWidth: '170',
    customRender(h, row) {
      return (
        <div>
          <p>用户id：{row['user']?.['id']}</p>

          <p>手机号：{row['user']?.['mobile']}</p>
          <p>余额：{row['user']?.['balance']}</p>
        </div>
      )
    }
  },

  {
    label: '订单时间',
    prop: 'start_time',
    minWidth: '240',
    customRender(h, row) {
      return (
        <p>
          <div>开始时间：{row['start_time']}</div>
          <div>结束时间：{row['end_time']}</div>
        </p>
      )
    }
  },

  {
    label: '订单状态',
    prop: 'order_status_dsc',
    minWidth: '100',
    customRender(h, row) {
      return (
        <p>
          <el-tag v-show={row['order_status'] == 20} size="small" type="primary">
            充电中
          </el-tag>
          <el-tag v-show={row['order_status'] == 10} size="small" type="warning">
            占位中
          </el-tag>
          <el-tag v-show={row['order_status'] == 30} size="small" type="success">
            已结束
          </el-tag>
        </p>
      )
    }
  },
  {
    label: '充电费用',
    prop: 'pay_time',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['charging_money']}</p>
    }
  },
  {
    label: '充电时长',
    prop: 'charging_time_human',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['charging_time_human']}</p>
    }
  },
  {
    label: '占位费用',
    prop: 'seat_money',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['seat_money']}</p>
    }
  },
  {
    label: '占位时长',
    prop: 'seat_time_human',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['seat_time_human']}</p>
    }
  },
  {
    label: '停车场',
    prop: 'charging_station',
    minWidth: '200',
    customRender(h, row) {
      return (
        <p>
          <div>名称：{row['charging_station']?.['name']}</div>

          <div>地址： {row['charging_station']?.['address']}</div>
        </p>
      )
    }
  },

  {
    label: '操作',
    minWidth: '120',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.order_pay_detail)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'PayDetail', params: { id: row['id'] } })}
          >
            详情
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectStation },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        station_id: '',
        order_no: '',
        timeSlot: [],
        order_status: '',
        uid: '',
        mobile: ''
      },
      dict_order_status
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},
  activated() {
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    },
    'searchData.type': {
      handler(newV, oldV) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      const { order_status, station_id, order_no, timeSlot, uid, mobile } = this.searchData
      let params = { page: this.currentPage, page_size: this.pageSize, order_status, station_id, order_no, uid, mobile }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 15px;
        margin-bottom: 10px;
        // .el-input {
        //   width: 200px;
        // }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      // .el-select .el-input {
      //   width: 100px;
      // }
      // .input-with-select {
      //   vertical-align: middle;
      // }
      // .input-with-select .el-input-group__prepend {
      //   background-color: #fff;
      // }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 10px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
